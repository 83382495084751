import './about.css'
import { graphql } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

// import Banner from '../components/landing/components/banner';
// import Feature from '../components/landing/components/feature';
// import Process from '../components/landing/components/process';
// import Partner from '../components/landing/components/partner';
// import Try from '../components/landing/components/try';
// import Certifications from '../components/landing/components/certifications';
import Header from '../components/landing/components/header';
import Footer from '../components/landing/components/footer';

function AboutPage() {

  return (
    <>
      <Helmet>
        <title>Extraise</title>
        <link rel="shortcut icon" href="/assets/images/logo/favourite_icon_1.png" />
        <link rel="stylesheet" type="text/css" href="/assets/css/bootstrap.min.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/icomoon.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/themify-icons.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/fontawesome-all.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/owl.carousel.min.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/owl.theme.default.min.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/aos.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/animate.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/splitting.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/magnific-popup.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/style.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/_extraise.css" />
        <script defer key="jquery" src="/assets/js/jquery-3.4.1.min.js" />
        <script defer key="popper" src="/assets/js/popper.min.js" />
        <script defer key="bootstrap" src="/assets/js/bootstrap.min.js" />
        <script defer key="owl-carousel" src="/assets/js/owl.carousel.min.js" />
        <script defer key="aos" src="/assets/js/aos.js" />
        <script defer key="splitting" src="/assets/js/splitting.js" />
        <script defer key="magnific-popup" src="/assets/js/magnific-popup.min.js" />
        <script defer key="isotope" src="/assets/js/isotope.pkgd.min.js" />
        <script defer key="masonry" src="/assets/js/masonry.pkgd.min.js" />
        <script defer key="imagesloaded" src="/assets/js/imagesloaded.pkgd.min.js" />
        <script defer key="parallax" src="/assets/js/parallax.min.js" />
        <script defer key="parallax-scroll" src="/assets/js/parallax-scroll.js" />
        <script defer key="gmaps" src="/assets/js/gmaps.js" />
        <script defer key="mCustomScrollbar" src="/assets/js/mCustomScrollbar.js" />
        <script defer key="custom" src="/assets/js/custom.js" />
      </Helmet>
      <Header />

      {/* <section id="breadcrumb_section" className="breadcrumb_section bg_gradient_blue deco_wrap d-flex align-items-center text-white clearfix" style={{ minHeight: "auto", padding: "55px 0" }}>
        <div className="container">
          <div className="breadcrumb_content text-center" data-aos-delay={100}>
            <h1 className="page_title" style={{ fontSize: "25px" }}>Pricing Plans</h1>
            <p className="mb-0">
              Stay ahead of the curve with
              Extraise Pro.
            </p>
          </div>
        </div>
        <div className="deco_image spahe_1" data-aos="fade-down" data-aos-delay={300}>
          <img src="/assets/images/shapes/shape_1.png" alt="spahe_not_found" />
        </div>
        <div className="deco_image spahe_2" data-aos-delay={400}>
          <img src="/assets/images/shapes/shape_2.png" alt="spahe_not_found" />
        </div>
      </section> */}
      <main>
        <section id="banner_section" className="banner_section software_banner deco_wrap d-flex align-items-center text-white clearfix" style={{ minHeight: "400px", backgroundColor: "#fbfbfd", paddingBottom: "220px", paddingTop: "200px" }} >
          <div className="container">
            <div className="row align-items-center justify-content-lg-between">

              <div className="col-lg-7">
                <div className="banner_content c_slide_in">
                  <h1 className="title_text mb-30 text_effect_wrap text_effect_wrap">
                    {/* <span className="text_effect text_effect_left">
                      About us
                    </span><br /> */}
                    <span className="text_effect text_effect_left">
                      Extraise is India’s Interactive Frontend learning Hub
                    </span>
                  </h1>
                  
                  <p className="mb-50" data-aos="fade-up" data-aos-delay={200}>
                    { } <br /> <small>{ }</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="deco_image bg_shape_1" data-background="/assets/images/banner/10_software/bg_1.png" />
          <div className="deco_image bg_shape_2" data-background="/assets/images/banner/10_software/bg_2.png" style={{ backgroundSize: "cover" }} />
          <div className="deco_image bg_shape_3" data-background="/assets/images/banner/10_software/bg_3.png" data-aos="zoom-out" data-aos-delay={100} />
          <div className="deco_image shape_1" data-aos="fade-left" data-aos-delay={500}>
            <img src="/assets/images/banner/10_software/shape_1.png" alt="shape_not_found" />
          </div>
          <div className="deco_image shape_2" data-aos="fade-right" data-aos-delay={700}>
            <img src="/assets/images/banner/10_software/shape_2.png" alt="shape_not_found" />
          </div>
          <div className="deco_image cloud_1" data-aos="fade-up" data-aos-delay={500}>
            <img src="/assets/images/banner/10_software/cloud_1.png" alt="shape_not_found" />
          </div>
          <div className="deco_image cloud_2" data-aos="fade-up" data-aos-delay={700}>
            <img src="/assets/images/banner/10_software/cloud_1.png" alt="shape_not_found" />
          </div>
        </section>

        <section className="about-section banner_section software_banner d-flex align-items-center clearfix" style={{ minHeight: "400px", backgroundColor: "#fbfbfd" }}>
          <div className="container">
            <div className="column">
              
                  <div className="sec-title">
                    <h2>About Extraise</h2>
                  </div>
                  <div className="text">
                    Welcome to Extraise, your premier destination for comprehensive online frontend development training. Our platform is designed to empower individuals with the knowledge and skills needed to thrive in the dynamic world of web development. Below is a glimpse into what makes Extraise the go-to choice for your learning journey.
                  </div>
               
            </div>
            <div className="column">
              <div className="sec-title">
                <h2>Our Mission</h2>
              </div>
              <div className="text">
                At Extraise, our mission is to bridge the gap between ambition and achievement. We believe in making quality frontend development education accessible to anyone, anywhere. Whether you're a beginner looking to kickstart your coding journey or an experienced developer seeking to enhance your skill set, Extraise is here to guide you every step of the way.
              </div>
            </div>
            <div className="column">
              <div className="sec-title">
                <h2>Expert Instructors</h2>
              </div>
              <div className="text">
                Our courses are crafted and delivered by industry experts with a wealth of experience in frontend development. These professionals bring real-world insights, practical tips, and a passion for teaching to ensure that you receive top-notch, relevant instruction.
              </div>
            </div>
            <div className="column">
              <div className="sec-title">
                <h2>Comprehensive Curriculum</h2>
              </div>
              <div className="text">
                Extraise offers a diverse and comprehensive curriculum that covers the latest technologies, frameworks, and best practices in frontend development. From HTML and CSS fundamentals to advanced JavaScript frameworks, our courses are structured to provide a well-rounded education.
              </div>
            </div>
            <div className="column">
              <div className="sec-title">
                <h2>Interactive Learning</h2>
              </div>
              <div className="text">
                Learning at Extraise is an engaging and interactive experience. Our platform incorporates hands-on exercises, projects, and quizzes to reinforce your understanding and build practical skills. We believe in learning by doing, and our courses reflect that philosophy.
              </div>
            </div>
            <div className="column">
              <div className="sec-title">
                <h2>Flexibility and Accessibility</h2>
              </div>
              <div className="text">
                We understand that everyone's learning journey is unique. Extraise offers flexible schedules, allowing you to learn at your own pace and on your own terms. Our platform is accessible from various devices, ensuring that you can continue your learning journey wherever and whenever it's most convenient for you.
              </div>
            </div>
            <div className="column">
              <div className="sec-title">
                <h2>Community Support</h2>
              </div>
              <div className="text">
                Learning is a social experience, and at Extraise, you're not alone. Join our vibrant community of learners, collaborate with peers, and connect with instructors. Our forums and discussion boards provide a space for sharing insights, troubleshooting challenges, and celebrating successes.
              </div>
            </div>
            <div className="column">
              <div className="sec-title">
                <h2>Start Your Journey with Extraise</h2>
              </div>
              <div className="text">
                Whether you're a beginner eager to explore the world of frontend development or a seasoned professional aiming to stay ahead in the field, Extraise is here to support your goals. Embark on your learning journey with us and unlock the doors to a world of opportunities in frontend development.
              </div>
              <div className="text">
                At Extraise, we believe in the transformative power of education. Join us and take the next step towards mastering frontend development. Your success begins here!
              </div>
            </div>
          </div>
        </section>
        {/* <Feature /> */}
        {/* <Process /> */}
        {/* <Partner /> */}
        {/* <Try /> */}

      </main>
      <Footer />
    </>
  );
}

AboutPage.displayName = 'AboutPage';

export default connect(null)(AboutPage);
